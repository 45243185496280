import "twin.macro";

import { FaIcon } from "@features/ui";
import { TrackingData } from "@models/TrackingData";

const TrackingNumber = ({
  trackingData,
  handleTrackingModal,
}: {
  trackingData: TrackingData;
  handleTrackingModal: () => void;
}) => {
  return (
    <div tw="mr-4">
      {trackingData.number}
      {trackingData.carrier?.toLowerCase() === "champion" ? (
        <a
          href={`http://portal.champlog.com/track/TrackResults.aspx?tracknbr=${trackingData.number}`}
          tw="text-primary-600 underline block"
          target="_blank"
          rel="noopener noreferrer"
        >
          View Champion tracking
          <FaIcon icon="external-link" tw="ml-1" />
        </a>
      ) : (
        <div
          role="link"
          tw="text-primary-600 underline mt-1 cursor-pointer"
          onClick={handleTrackingModal}
        >
          View tracking info
        </div>
      )}
    </div>
  );
};

export default TrackingNumber;
