import "twin.macro";
import tw, { css } from "twin.macro";

import { useState } from "react";
import { useSelector } from "react-redux";

import { Cancel, Edit } from "@mui/icons-material";
import { IconButton, Tooltip } from "@mui/material";

import { format } from "date-fns";

import ReactQueryTable, {
  Column,
  PaginatedResourceProps,
} from "@components/Table/ReactQueryTable";
import TrackingNumber from "@features/orderHistory/components/TrackingNumber";
import {
  OrderVariant,
  orderVariantDatePropertyMap,
} from "@models/OrderVariant";
import {
  formatDateString,
  formatMoney,
  stringToCents,
  upCase,
} from "@utility/utilityFunctions";

import TrackingModal from "../Utility/Modals/TrackingModal";
import ItemColumn from "./ItemColumn";

const OrderVariantTable = ({
  orderVariantData,
  tableProps,
  handleEditOpen,
  handleCancelOpen,
}: {
  orderVariantData: OrderVariant[];
  tableProps: PaginatedResourceProps;
  handleEditOpen: (type: "variant" | "order", ov: OrderVariant) => void;
  handleCancelOpen: (type: "variant" | "order", ov: OrderVariant) => void;
}) => {
  const { role } = useSelector((state: any) => state.currentUser);

  const [trackingModal, setTrackingModal] = useState({
    open: false,
    id: "",
  });

  const handleTrackingModal = (id) => {
    setTrackingModal({
      open: true,
      id: id,
    });
  };

  const handleTrackingClose = () => setTrackingModal({ open: false, id: "" });

  const columns: Column<OrderVariant>[] = [
    {
      id: "actions",
      label: "Maintenance",
      render: (_, row) => {
        const disabled =
          row.status === "canceled" || Boolean(row.actualShipDate);

        return (
          <div style={{ display: "flex" }}>
            <Tooltip title="Edit Order Item">
              <span style={{ marginRight: "5px" }}>
                <IconButton
                  disabled={disabled}
                  onClick={(evt) => {
                    evt.stopPropagation();
                    handleEditOpen("variant", row);
                  }}
                  size="large"
                  color="secondary"
                >
                  <Edit />
                </IconButton>
              </span>
            </Tooltip>
            <Tooltip title="Cancel Order Item">
              <span>
                <IconButton
                  disabled={disabled}
                  onClick={(evt) => {
                    evt.stopPropagation();
                    handleCancelOpen("variant", row);
                  }}
                  size="large"
                  color="secondary"
                >
                  <Cancel />
                </IconButton>
              </span>
            </Tooltip>
          </div>
        );
      },
    },
    {
      id: "item",
      label: "Item",
      sort: "sku",
      render: (_, row) => <ItemColumn row={row} />,
    },
    { id: "count", label: "Total Qty", render: (_, row) => row.qty },
    {
      id: "discount",
      label: "Promotion Discount",
      render: (_, row) => formatMoney(stringToCents(row.promotionDiscount)),
      align: "right",
    },
    {
      id: "price",
      label: "Price",
      render: (price) => formatMoney(stringToCents(price)),
      align: "right",
    },
    {
      id: "in-market-date",
      label: "In Market Date",
      render: (date, row) => {
        const dateProperty = orderVariantDatePropertyMap[row.orderType];
        return dateProperty && row[dateProperty]
          ? format(new Date(row[dateProperty]), "MM/dd/yyyy")
          : "";
      },
    },
    {
      id: "actualShipDate",
      label: "Act. Ship Date",
      render: (d) => (d ? formatDateString(d) : ""),
    },
    {
      id: "carrier",
      label: "Carrier",
      render: (_, row) => row.trackingData?.carrier,
    },
    {
      id: "trackingData",
      label: "Tracking",
      render: (trackingData, row) =>
        trackingData && (
          <TrackingNumber
            trackingData={trackingData}
            handleTrackingModal={() => handleTrackingModal(row.id)}
          />
        ),
    },
    { id: "status", label: "Status", render: (s) => upCase(s) },
  ];

  const filteredColumns = ["admin", "super"].includes(role)
    ? columns
    : columns.filter((c) => c.id !== "actions");

  return (
    <>
      {trackingModal.open && trackingModal.id && (
        <TrackingModal {...trackingModal} handleClose={handleTrackingClose} />
      )}
      <ReactQueryTable
        columns={filteredColumns}
        rows={orderVariantData}
        maxHeight={700}
        rowProps={(row: OrderVariant) => ({
          css:
            row.status === "canceled" &&
            css`
              ${tw`bg-red-50`}
              & td {
                ${tw`text-red-900`}
              }
            `,
        })}
        {...tableProps}
      />
    </>
  );
};

export default OrderVariantTable;
